import { http, HttpResponse } from 'msw'
import { Car, Report, ReportFormData, UpdateReportData } from '../types'

let cars: Car[] = [
  {
    id: 1,
    car_type: 'Car White',
    capacity: 4,
    description: 'Suzuki Alto',
    status: '利用可能',
  },
  {
    id: 2,
    car_type: 'Car Silver',
    capacity: 4,
    description: 'Suzuki Alto',
    status: '利用可能',
  },
]

const reports: Report[] = []
let reportIdCounter = 1

export const handlers = [
  http.get('/cars', () => {
    return HttpResponse.json(cars)
  }),

  http.post<never, ReportFormData>('/api/reports', async ({ request }) => {
    const reportData: ReportFormData = await request.json()

    if (
      !reportData.car_id ||
      !reportData.driver_id ||
      !reportData.purpose ||
      reportData.departure_odo === undefined
    ) {
      return HttpResponse.json(
        { error: '必須フィールドが不足しています' },
        { status: 400 },
      )
    }

    const newReport: Report = {
      id: reportIdCounter,
      car_id: reportData.car_id,
      car_type:
        cars.find((car) => car.id === reportData.car_id)?.car_type ?? '',
      driver_id: reportData.driver_id,
      passengers: reportData.passengers,
      purpose: reportData.purpose,
      departure_time: new Date().toISOString(),
      departure_odo: reportData.departure_odo,
      arrival_time: null,
      arrival_odo: null,
      is_abnormal: null,
      gas_meter: null,
      has_garbage: reportData.has_garbage,
      departure_gas_meter: reportData.departure_gas_meter,
    }

    reports.push(newReport)
    reportIdCounter += 1

    // 車両の状態を更新
    cars = cars.map((car) =>
      car.id === reportData.car_id ? { ...car, status: '使用中' } : car,
    )

    return HttpResponse.json({
      message: '出発報告書が作成されました',
      reportId: newReport.id,
    })
  }),

  // 車両状態の取得
  http.get('/api/car-status', () => {
    const carStatuses: { [key: number]: string } = {}
    cars.forEach((car) => {
      carStatuses[car.id] = car.status
    })
    return HttpResponse.json(carStatuses)
  }),

  http.get('/api/latest-arrival-odo', ({ request }) => {
    const url = new URL(request.url)
    const car_id = url.searchParams.get('carId')
    if (!car_id) {
      return HttpResponse.json(
        { error: '車両IDが指定されていません' },
        { status: 400 },
      )
    }
    const carIdNumber = parseInt(car_id, 10)
    const latestReport = reports
      .filter((report) => report.car_id === carIdNumber)
      .sort(
        (a, b) =>
          new Date(b.departure_time).getTime() -
          new Date(a.departure_time).getTime(),
      )[0]

    return HttpResponse.json({ odo: latestReport?.arrival_odo || 0 })
  }),

  http.get('/users', () => {
    const users = [
      { id: 1, email: 'user1@example.com' },
      { id: 2, email: 'user2@example.com' },
      { id: 3, email: 'user3@example.com' },
    ]
    return HttpResponse.json(users)
  }),

  http.get('/api/latest-report-id', ({ request }) => {
    const url = new URL(request.url)
    const car_id = url.searchParams.get('carId')
    if (!car_id) {
      return HttpResponse.json(
        { error: '車両IDが指定されていません' },
        { status: 400 },
      )
    }
    const carIdNumber = parseInt(car_id, 10)
    const latestReport = reports
      .filter((report) => report.car_id === carIdNumber)
      .sort(
        (a, b) =>
          new Date(b.departure_time).getTime() -
          new Date(a.departure_time).getTime(),
      )[0]

    return HttpResponse.json({ reportId: latestReport?.id || null })
  }),

  http.put<{ reportId: string }, UpdateReportData>(
    '/api/reports/:reportId',
    async ({ request, params }) => {
      const reportId = parseInt(params.reportId, 10)
      const updateData: UpdateReportData = await request.json()

      const reportIndex = reports.findIndex((report) => report.id === reportId)
      if (reportIndex === -1) {
        return HttpResponse.json(
          { error: '報告が見つかりません' },
          { status: 404 },
        )
      }

      reports[reportIndex] = {
        ...reports[reportIndex],
        arrival_time: updateData.arrival_time,
        arrival_odo: updateData.arrival_odo,
        is_abnormal: updateData.is_abnormal,
        gas_meter: updateData.gas_meter,
      }

      // 車両の状態を更新
      const updatedReport = reports[reportIndex]
      cars = cars.map((car) =>
        car.id === updatedReport.car_id ? { ...car, status: '利用可能' } : car,
      )

      return HttpResponse.json({ message: '到着報告が更新されました' })
    },
  ),

  http.get('/api/active-reports', () => {
    const activeReports = reports.filter((report) => !report.arrival_time)
    return HttpResponse.json(activeReports)
  }),

  http.get('/api/reports/:id', ({ params }) => {
    const id = Number(params.id)
    const report = reports.find((r) => r.id === id)
    if (report) {
      return HttpResponse.json(report)
    } else {
      return new HttpResponse(null, { status: 404 })
    }
  }),
  http.get('/api/reports/:reportId/departure-odo', ({ params }) => {
    const reportId = parseInt(params.reportId as string, 10)

    const report = reports.find((r) => r.id === reportId)

    if (report) {
      return HttpResponse.json({ departure_odo: report.departure_odo })
    } else {
      return new HttpResponse(null, {
        status: 404,
        statusText: 'Report not found',
      })
    }
  }),
]
