import React, { useState } from 'react'
import { login, LoginResponse } from '../utils/auth'
import { useHistory } from 'react-router-dom'
import '../styles/Login.scss'

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string | null>(null)
  const history = useHistory()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      const response: LoginResponse = await login(email, password)
      // ログイン成功時の処理
      console.log('Login successful:', response.user)
      // ログイン成功後にホーム画面などに遷移
      history.push('/home')
    } catch (error) {
      // エラーハンドリング
      setError('Invalid credentials')
    }
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleSubmit(e).catch((error) => {
      console.error('Login error:', error)
    })
  }

  return (
    <div className="login-page">
      <h1>配車アプリ</h1>
      <form onSubmit={onSubmit}>
        {error && <p>{error}</p>}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  )
}

export default Login
