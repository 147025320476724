import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { worker } from './mocks/browser'

async function startApp() {
  if (process.env.NODE_ENV === 'development') {
    await worker.start({
      onUnhandledRequest: 'bypass',
    })
  }

  const rootElement = document.getElementById('root')
  if (rootElement) {
    const root = createRoot(rootElement)
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    )
  } else {
    console.error('Root element not found')
  }
}

void startApp()
