const Home = () => {
  return (
    <div>
      <h1>佐藤下宿（五稜郭） 配車アプリ</h1>
      {<p>Welcome, </p>}
    </div>
  )
}

export default Home
