import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Home from './pages/Home'
import Login from './pages/Login'
import { CarManagement } from './pages/CarManagement'
// import PrivateRoute from './components/PrivateRoute';
// import { AuthProvider } from './context/AuthProvider';

const App: React.FC = () => {
  return (
    // <AuthProvider>
    <Router>
      <div className="App">
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/cars" component={CarManagement} />
      </div>
    </Router>
    // </AuthProvider>
  )
}

export default App
