import axios from 'axios'
import { Car } from '../types/car'
import { Report, ReportFormData, UpdateReportData } from '../types/report'

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000'

export const fetchCars = async (): Promise<Car[]> => {
  const response = await axios.get<Car[]>(`${API_BASE_URL}/cars`)
  return response.data
}

export const fetchUsers = async (): Promise<
  { id: number; email: string }[]
> => {
  const response = await axios.get<{ id: number; email: string }[]>(
    `${API_BASE_URL}/users`,
  )
  return response.data
}

export const createReport = async (
  reportData: ReportFormData,
): Promise<Report> => {
  const response = await axios.post<Report>(
    `${API_BASE_URL}/api/reports`,
    reportData,
  )
  return response.data
}

export const updateReport = async (
  reportId: number,
  updateData: UpdateReportData,
): Promise<Report> => {
  const response = await axios.put<Report>(
    `${API_BASE_URL}/api/reports/${reportId}`,
    updateData,
  )
  return response.data
}

export const getLatestReportId = async (
  carId: number,
): Promise<number | null> => {
  const response = await axios.get<{ reportId: number | null }>(
    `${API_BASE_URL}/api/latest-report-id?carId=${carId}`,
  )
  return response.data.reportId
}

export const getCarStatus = async (): Promise<Record<number, string>> => {
  const response = await axios.get<Record<number, string>>(
    `${API_BASE_URL}/api/car-status`,
  )
  return response.data
}

export const getLatestArrivalOdo = async (carId: number): Promise<number> => {
  const response = await axios.get<{ odo: number }>(
    `${API_BASE_URL}/api/latest-arrival-odo?carId=${carId}`,
  )
  return response.data.odo
}

export const getReportById = async (reportId: number): Promise<Report> => {
  const response = await axios.get<Report>(
    `${API_BASE_URL}/api/reports/${reportId}`,
  )
  return response.data
}

export const fetchActiveReports = async (): Promise<Report[]> => {
  const response = await axios.get<Report[]>(
    `${API_BASE_URL}/api/active-reports`,
  )
  return response.data
}

export const getDepartureOdo = async (reportId: number): Promise<number> => {
  try {
    const response = await axios.get<{ departure_odo: number }>(
      `${API_BASE_URL}/api/reports/${reportId}/departure-odo`,
    )
    return response.data.departure_odo
  } catch (error) {
    console.error('Failed to fetch departure ODO:', error)
    throw error
  }
}
