import React, { useState, useEffect, useCallback } from 'react'
import { Car, Report, ReportFormData, UpdateReportData, User } from '../types'
import {
  fetchCars,
  fetchActiveReports,
  createReport,
  updateReport,
  fetchUsers,
} from '../utils/api'
import { ReportForm } from '../components/ReportForm'
import '../styles/CarManagement.scss'

interface CarCardProps {
  car: Car
  onClick: (car: Car) => void
}

const CarCard: React.FC<CarCardProps> = ({ car, onClick }) => (
  <div
    className={`car-card ${car.status === '使用中' ? 'in-use' : ''}`}
    onClick={() => onClick(car)}
  >
    <h3>{car.car_type}</h3>
    <p>定員: {car.capacity}人</p>
    <p>説明: {car.description}</p>
    <p>状態: {car.status}</p>
  </div>
)

export const CarManagement: React.FC = () => {
  const [cars, setCars] = useState<Car[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [activeReports, setActiveReports] = useState<Report[]>([])
  const [selectedCar, setSelectedCar] = useState<Car | null>(null)
  const [error, setError] = useState<string | null>(null)

  const loadData = useCallback(async () => {
    try {
      const [fetchedCars, fetchedReports, fetchedUsers] = await Promise.all([
        fetchCars(),
        fetchActiveReports(),
        fetchUsers(),
      ])
      setCars(fetchedCars)
      setActiveReports(fetchedReports)
      setUsers(fetchedUsers)
      setError(null)
    } catch (error) {
      console.error('Failed to load data:', error)
      setError('データの読み込みに失敗しました。')
    }
  }, [])

  useEffect(() => {
    void loadData()
  }, [loadData])

  const handleReportSubmit = useCallback(
    async (data: ReportFormData | UpdateReportData, isDepature: boolean) => {
      try {
        if (isDepature) {
          const reportData = data as ReportFormData
          const carInUse = cars.find(
            (car) => car.id === reportData.car_id && car.status === '使用中',
          )
          if (carInUse) {
            throw new Error('この車両は既に使用中です。')
          }
          await createReport(reportData)
        } else if (selectedCar) {
          const activeReport = activeReports.find(
            (report) => report.car_id === selectedCar.id,
          )
          if (activeReport) {
            await updateReport(activeReport.id, data as UpdateReportData)
          } else {
            throw new Error('アクティブな報告が見つかりません。')
          }
        }
        await loadData()
        setSelectedCar(null)
        setError(null)
      } catch (error) {
        console.error('Failed to submit report:', error)
        setError(
          error instanceof Error ? error.message : '報告の提出に失敗しました。',
        )
      }
    },
    [loadData, selectedCar, activeReports, cars],
  )

  const handleSubmit = (
    data: ReportFormData | UpdateReportData,
    isDepature: boolean,
  ) => {
    void handleReportSubmit(data, isDepature)
  }

  const handleCarSelect = useCallback((car: Car) => {
    setSelectedCar(car)
  }, [])

  return (
    <div className="car-management">
      <h1>車両管理システム</h1>

      {error && <div className="error-message">{error}</div>}

      <h2>車両一覧</h2>
      <div className="car-grid">
        {cars.map((car) => (
          <CarCard key={car.id} car={car} onClick={handleCarSelect} />
        ))}
      </div>

      <ReportForm
        cars={cars}
        users={users}
        onSubmit={handleSubmit}
        selectedCar={selectedCar}
        activeReportId={
          activeReports.find((report) => report.car_id === selectedCar?.id)
            ?.id || null
        }
      />

      <h2>出発済みの車両</h2>
      <ul className="active-reports">
        {activeReports.map((report) => (
          <li key={report.id}>
            {report.car_type} - 出発時刻:{' '}
            {new Date(report.departure_time).toLocaleString()}
          </li>
        ))}
      </ul>
    </div>
  )
}
