import React, { useState, useEffect, useCallback } from 'react'
import { Car, ReportFormData, UpdateReportData, User } from '../types'
import { getLatestArrivalOdo, getDepartureOdo } from '../utils/api'

interface ReportFormProps {
  cars: Car[]
  users: User[]
  onSubmit: (
    data: ReportFormData | UpdateReportData,
    isDepature: boolean,
  ) => void
  selectedCar: Car | null
  activeReportId: number | null
}

export const ReportForm: React.FC<ReportFormProps> = ({
  cars,
  users,
  onSubmit,
  selectedCar,
  activeReportId,
}) => {
  const [isDepature, setIsDepature] = useState(true)
  const [formData, setFormData] = useState<ReportFormData>({
    car_id: 0,
    driver_id: 0,
    passengers: [],
    purpose: '',
    departure_odo: 0,
    has_garbage: false,
    departure_gas_meter: 0,
  })
  const [arrivalData, setArrivalData] = useState<UpdateReportData>({
    arrival_time: '',
    arrival_odo: 0,
    is_abnormal: false,
    gas_meter: 0,
    driver_id: 0,
    passengers: [],
  })
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [departureOdo, setDepartureOdo] = useState<number>(0)

  useEffect(() => {
    if (selectedCar) {
      setIsDepature(selectedCar.status !== '使用中')
      setFormData((prev) => ({ ...prev, car_id: selectedCar.id }))
      if (selectedCar.status === '使用中') {
        setArrivalData((prev) => ({
          ...prev,
          driver_id: formData.driver_id,
          passengers: formData.passengers,
          gas_meter: formData.departure_gas_meter,
        }))
        void fetchDepartureOdo()
      } else {
        void fetchLatestArrivalOdo()
      }
    }
  }, [selectedCar])

  const fetchDepartureOdo = async () => {
    if (activeReportId) {
      try {
        const odo = await getDepartureOdo(activeReportId)
        setDepartureOdo(odo)
      } catch (error) {
        console.error('Failed to fetch departure ODO:', error)
        setErrorMessage('出発時ODOの取得に失敗しました。')
      }
    }
  }

  const fetchLatestArrivalOdo = async () => {
    if (selectedCar) {
      try {
        const odo = await getLatestArrivalOdo(selectedCar.id)
        setDepartureOdo(odo)
        setFormData((prev) => ({ ...prev, departure_odo: odo }))
      } catch (error) {
        console.error('Failed to fetch latest arrival ODO:', error)
        setErrorMessage('最新の到着ODOの取得に失敗しました。')
      }
    }
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value, type } = e.target
    const parsedValue =
      type === 'checkbox'
        ? (e.target as HTMLInputElement).checked
        : name === 'car_id' ||
          name === 'driver_id' ||
          name === 'departure_odo' ||
          name === 'departure_gas_meter' ||
          name === 'arrival_odo' ||
          name === 'gas_meter'
        ? Number(value)
        : value

    if (isDepature) {
      setFormData((prev) => ({
        ...prev,
        [name]: parsedValue,
      }))
    } else {
      setArrivalData((prev) => ({
        ...prev,
        [name]: parsedValue,
      }))
    }
  }

  const handlePassengerChange = (
    index: number,
    value: string,
    isArrival: boolean,
  ) => {
    const passengerId = Number(value)
    if (isArrival) {
      setArrivalData((prev) => {
        const newPassengers = [...prev.passengers]
        newPassengers[index] = passengerId
        return { ...prev, passengers: newPassengers }
      })
    } else {
      setFormData((prev) => {
        const newPassengers = [...prev.passengers]
        newPassengers[index] = passengerId
        return { ...prev, passengers: newPassengers }
      })
    }
  }

  const handleAddPassenger = (isArrival: boolean) => {
    const currentPassengers = isArrival
      ? arrivalData.passengers
      : formData.passengers
    const maxPassengers = selectedCar ? selectedCar.capacity - 1 : 0 // 運転手を除いた最大同乗者数

    console.log('Current passengers:', currentPassengers.length)
    console.log('Max passengers:', maxPassengers)

    if (currentPassengers.length < maxPassengers) {
      if (isArrival) {
        setArrivalData((prev) => ({
          ...prev,
          passengers: [...prev.passengers, 0],
        }))
      } else {
        setFormData((prev) => ({
          ...prev,
          passengers: [...prev.passengers, 0],
        }))
      }
      console.log('Passenger added successfully')
    } else {
      console.log('Cannot add more passengers: capacity reached')
    }
  }

  const handleRemovePassenger = (index: number, isArrival: boolean) => {
    if (isArrival) {
      setArrivalData((prev) => {
        const newPassengers = [...prev.passengers]
        newPassengers.splice(index, 1)
        return { ...prev, passengers: newPassengers }
      })
    } else {
      setFormData((prev) => {
        const newPassengers = [...prev.passengers]
        newPassengers.splice(index, 1)
        return { ...prev, passengers: newPassengers }
      })
    }
  }

  const isUserSelected = (userId: number, isArrival: boolean): boolean => {
    const data = isArrival ? arrivalData : formData
    return data.driver_id === userId || data.passengers.includes(userId)
  }

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (isDepature) {
        if (!formData.car_id || !formData.driver_id || !formData.purpose) {
          setErrorMessage('車両、運転手、目的を選択してください')
          return
        }
        onSubmit(formData, isDepature)
      } else {
        if (!selectedCar) {
          setErrorMessage('車両を選択してください')
          return
        }
        if (arrivalData.arrival_odo === 0) {
          setErrorMessage('到着時ODOを入力してください')
          return
        }
        onSubmit(
          {
            ...arrivalData,
            arrival_time: new Date().toISOString(),
          },
          isDepature,
        )
      }

      // フォームの値を初期化
      setFormData({
        car_id: 0,
        driver_id: 0,
        passengers: [],
        purpose: '',
        departure_odo: 0,
        has_garbage: false,
        departure_gas_meter: 0,
      })
      setArrivalData({
        arrival_time: '',
        arrival_odo: 0,
        is_abnormal: false,
        gas_meter: 0,
        driver_id: 0,
        passengers: [],
      })
      // 帰着報告後に出発報告に遷移
      setIsDepature(true)
      setErrorMessage(null) // エラーメッセージをクリア
    },
    [formData, arrivalData, isDepature, onSubmit, selectedCar],
  )

  const odoDifference = arrivalData.arrival_odo - (departureOdo || 0)

  return (
    <div className="report-form">
      <div className="tab-buttons">
        <button
          type="button"
          className={isDepature ? 'active' : ''}
          onClick={() => setIsDepature(true)}
          disabled={selectedCar?.status === '使用中'}
        >
          出発報告
        </button>
        <button
          type="button"
          className={!isDepature ? 'active' : ''}
          onClick={() => setIsDepature(false)}
          disabled={selectedCar?.status !== '使用中'}
        >
          帰着報告
        </button>
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="car_id">車両:</label>
          <select
            id="car_id"
            name="car_id"
            value={formData.car_id}
            onChange={handleChange}
            disabled={!!selectedCar}
          >
            <option value="">選択してください</option>
            {cars.map((car) => (
              <option
                key={car.id}
                value={car.id}
                disabled={
                  isDepature ? car.status === '使用中' : car.status !== '使用中'
                }
              >
                {car.car_type}
              </option>
            ))}
          </select>
        </div>
        {isDepature ? (
          <>
            <div className="form-group">
              <label htmlFor="driver_id">運転手:</label>
              <select
                id="driver_id"
                name="driver_id"
                value={formData.driver_id}
                onChange={handleChange}
              >
                <option value="">選択してください</option>
                {users.map((user) => (
                  <option
                    key={user.id}
                    value={user.id}
                    disabled={
                      isUserSelected(user.id, false) &&
                      formData.driver_id !== user.id
                    }
                  >
                    {user.email}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>同乗者:</label>
              {formData.passengers.map((passengerId, index) => (
                <div key={index}>
                  <select
                    value={passengerId}
                    onChange={(e) =>
                      handlePassengerChange(index, e.target.value, false)
                    }
                  >
                    <option value="">選択してください</option>
                    {users.map((user) => (
                      <option
                        key={user.id}
                        value={user.id}
                        disabled={
                          isUserSelected(user.id, false) &&
                          passengerId !== user.id
                        }
                      >
                        {user.email}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    onClick={() => handleRemovePassenger(index, false)}
                  >
                    削除
                  </button>
                </div>
              ))}
              {selectedCar &&
                formData.passengers.length < selectedCar.capacity - 1 && (
                  <button
                    type="button"
                    onClick={() => handleAddPassenger(false)}
                  >
                    同乗者を追加
                  </button>
                )}
            </div>
            <div className="form-group">
              <label>目的:</label>
              <div className="radio-group">
                {['通学', '私用', '給油', 'その他'].map((purpose) => (
                  <label key={purpose} className="radio-label">
                    <input
                      type="radio"
                      name="purpose"
                      value={purpose}
                      checked={formData.purpose === purpose}
                      onChange={handleChange}
                    />
                    {purpose}
                  </label>
                ))}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="departure_odo">出発時ODO:</label>
              <input
                type="number"
                id="departure_odo"
                name="departure_odo"
                value={formData.departure_odo}
                onChange={handleChange}
                className="odo-input"
              />
            </div>
            <div className="form-group">
              <label htmlFor="departure_gas_meter">出発時メータ:</label>
              <input
                type="number"
                id="departure_gas_meter"
                name="departure_gas_meter"
                value={formData.departure_gas_meter}
                onChange={handleChange}
                className="odo-input"
              />
            </div>
            <div className="form-group">
              <label>ゴミの有無:</label>
              <div className="radio-group">
                {['あり', 'なし'].map((option) => (
                  <label key={option} className="radio-label">
                    <input
                      type="radio"
                      name="has_garbage"
                      value={option}
                      checked={formData.has_garbage === (option === 'あり')}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          has_garbage: e.target.value === 'あり',
                        }))
                      }
                    />
                    {option}
                  </label>
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="form-group">
              <label htmlFor="driver_id">運転手:</label>
              <select
                id="driver_id"
                name="driver_id"
                value={arrivalData.driver_id}
                onChange={handleChange}
              >
                <option value="">選択してください</option>
                {users.map((user) => (
                  <option
                    key={user.id}
                    value={user.id}
                    disabled={
                      isUserSelected(user.id, true) &&
                      arrivalData.driver_id !== user.id
                    }
                  >
                    {user.email}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>同乗者:</label>
              {arrivalData.passengers.map((passengerId, index) => (
                <div key={index}>
                  <select
                    value={passengerId}
                    onChange={(e) =>
                      handlePassengerChange(index, e.target.value, true)
                    }
                  >
                    <option value="">選択してください</option>
                    {users.map((user) => (
                      <option
                        key={user.id}
                        value={user.id}
                        disabled={
                          isUserSelected(user.id, true) &&
                          passengerId !== user.id
                        }
                      >
                        {user.email}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    onClick={() => handleRemovePassenger(index, true)}
                  >
                    削除
                  </button>
                </div>
              ))}
              <button type="button" onClick={() => handleAddPassenger(true)}>
                同乗者を追加
              </button>
            </div>
            <div className="form-group">
              <label>出発時ODO:</label>
              <span>
                {departureOdo !== null ? departureOdo : '読み込み中...'}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="arrival_odo">到着時ODO:</label>
              <input
                type="number"
                id="arrival_odo"
                name="arrival_odo"
                value={arrivalData.arrival_odo}
                onChange={handleChange}
                className="odo-input"
                required
              />
              <p>走行距離: {odoDifference} km</p>
            </div>
            <div className="form-group">
              <label htmlFor="gas_meter">ガソリン残量:</label>
              <input
                type="number"
                id="gas_meter"
                name="gas_meter"
                value={arrivalData.gas_meter}
                onChange={handleChange}
                className="odo-input"
              />
            </div>
            <div className="form-group">
              <label>異常の有無:</label>
              <div className="radio-group">
                {['あり', 'なし'].map((option) => (
                  <label key={option} className="radio-label">
                    <input
                      type="radio"
                      name="is_abnormal"
                      value={option}
                      checked={arrivalData.is_abnormal === (option === 'あり')}
                      onChange={(e) =>
                        setArrivalData((prev) => ({
                          ...prev,
                          is_abnormal: e.target.value === 'あり',
                        }))
                      }
                    />
                    {option}
                  </label>
                ))}
              </div>
            </div>
          </>
        )}
        <button type="submit" className="submit-button">
          {isDepature ? '出発報告を提出' : '帰着報告を提出'}
        </button>
      </form>
    </div>
  )
}
