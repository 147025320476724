// src/utils/config.ts
import axios, { AxiosInstance } from 'axios'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || ''
// export const API_BASE_URL = 'http://localhost:3002';

export const client: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
})
